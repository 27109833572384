
import { getWindowDimensions, getStorage, setStorage, removeStored } from './general'
import abi from '../utils/abi.json'
import tokenAbi from '../utils/tokenAbi.json'

export const initialState = {
    abi,
    tokenAbi,
    contract: null,
    provider: null,
    tokenContract: null,
    signer: null,
    reserve: null,
    claimsCounts: null,
    minReferralCount: 3,
    promoTitle: 'The BINGO 2 Earn',
    contractAddress: '0xf2c4f1bc82998e0DF6fb0869b318CeC11E3F1525',
    airDropToken: '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    networkTitle: 'Polygon Matic',
    allowedChainIds: [137n],
    minTokenBalanceRequired: '10,000,000,000',
    totalAirdropAmount: '10 Billion',
    startDate: '17th January',
    endDate: '24th January',
    tokenName: 'Polydoge Tokens',
    networkSymbol: 'MATIC',
    airTokenName: 'Polydoge Air Tokens',
    airTokenSymbol: 'aPOLYDOGE',
    activeTheme: getStorage('cookies', 'theme') ? getStorage('cookies', 'theme') : "dark",
    referrer: getStorage('cookies', 'referrer') ? getStorage('cookies', 'referrer') : null,
    referrals: 0,
    windowDimensions: getWindowDimensions(),
    wallet: getStorage('session-storage', 'wallet') ? getStorage('session-storage', 'wallet') : null,
    banner: null,
    showHeader: true,
    showFooter: true,
    colors: {
        primary: '#000b26',
        secondary: '#6a2ef0',
        dark: '#1e272a',
        darker: '#12181a',
        lighter: '#2a373b',
        light: '#f2f2f2',
        green: '#228b22'
    },
    showFlashNotification: null,
    showWalletOptions: null,
    content: null,
    notification: null,
    loading: null,
    modal: null,
    balance: 0
}

const reducer = (state, action)=>{
    switch (action.type) {
        case 'SET_THEME':
            setStorage('cookies', 'theme', state.activeTheme === 'dark' ? 'light' : 'dark')
            return {
                ...state,
                activeTheme: state.activeTheme === 'dark' ? 'light' : 'dark'
            }
        case 'SET_WALLET':
            setStorage('session-storage', 'wallet', action.address)
            return {
                ...state,
                wallet: action.address || state.wallet,
                provider: action.provider || state.provider,
                signer: action.signer || state.signer
            }
        case 'CLEAR_WALLET':
            removeStored('session-storage', 'wallet')
            return {
                ...state,
                wallet: null,
                provider: null,
                signer: null
            }
        case 'TOGGLE_SHOW_WALLET_OPTIONS':
            return {
                ...state,
                showWalletOptions: action.option
            }
        case 'TOGGLE_CONTENT':
            return {
                ...state,
                content: action.content,
                balance: action.balance || 0
            }
        case 'SET_DIMENSIONS':
            return {
                ...state,
                windowDimensions: getWindowDimensions()
            }
        case 'TOGGLE_HEADER':
            return {
                ...state,
                showHeader: action.choice
            }
        case 'TOGGLE_FOOTER':
            return {
                ...state,
                showFooter: action.choice
            }
        case 'TOGGLE_LOADER':
            return {
                ...state,
                loading: state.loading != null ? null : true
            }
        case 'SET_NOTIFICATION':
            return {
                ...state,
                showFlashNotification: action.data
            }
        case 'CLEAR_NOTIFICATION':
            return {
                ...state,
                showFlashNotification: null
            }
        case 'SET_MODAL':
            return {
                ...state,
                modal: action.data
            }
        case 'SET_CONTRACT':
            return {
                ...state,
                provider: action.provider || state.provider,
                contract: action.contract || state.contract,
                tokenContract: action.tokenContract || state.tokenContract,
                signer: action.signer || state.signer
            }
        case 'SET_RESERVE':
            return {
                ...state,
                reserve: action.reserve || state.reserve,
                claimsCounts: action.claimsCounts || state.claimsCounts,
                referrals: action.referrals || state.referrals 
            }
        case 'SET_REFERRER':
            if (action.referrer)
                setStorage('cookies', 'referrer', action.referrer)
            return {
                ...state,
                referrer: action.referrer || state.referrer
            }
        
        default:
            return state;
    }
}

export default reducer;
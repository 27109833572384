import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import CheckEligibility from './CheckEligibility';
import { useLocation } from 'react-router-dom';

const Content = () => {
    const [{ content }, dispatch] = useStateValue();
    const [contentDisplay, setContentDisplay] = useState(<CheckEligibility />);


    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const ref = queryParams.get('ref')

    if (ref != null)
        dispatch({
            type: 'SET_REFERRER',
            referrer: ref
        })

    useEffect(() => {
        if (content != null)
            setContentDisplay(content)
    }, [content])
    return (
        <main>
            <div className='content-container'>
                {contentDisplay}
            </div>
            <div className="floater"></div>
        </main>
    )
}

export default Content
